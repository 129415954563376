<template>
  <v-bottom-navigation app hide-on-scroll grow>
    <v-btn v-for="item in items" :key="item.title" :to="{ name: item.name }">
      <span>{{ item.title }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
  <!-- <v-navigation-drawer v-model="drawer" app class="light-blue">
    <v-list dense>
      <v-list-item v-for="item in items" :key="item.title" link>
        <v-list-item-icon>
          <v-icon class="white--text">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer> -->
</template>

<script>
export default {
  name: "navigation-drawer",

  props: ["navbar"],

  watch: {
    navbar: function(val) {
      console.log(val);
      this.drawer = val;
    },
    drawer: function() {
      this.$emit("onNavBar", this.drawer);
    },
  },

  data: () => ({
    drawer: false,
    items: [
      { title: "Inicio", icon: "mdi-home", name: "home" },
      { title: "Mis Recetas", icon: "mdi-medical-bag", name: "recetas" },
      {
        title: "Configuración",
        icon: "mdi-account-settings",
        name: "configuracion",
      },
    ],
    mini: true,
  }),

  mounted() {},
};
</script>

<style></style>
